import { Swiper } from "swiper11";
import { SwiperOptions } from "swiper11/types/swiper-options";

export function autoInitSwiper({ page, marker, groupName, pane, controls, swiperOtions }: AutoSwiper): void {
  const PAGE_DOM = $(page);
  const SWIPER_GROUP = PAGE_DOM.find(marker);
  if (!PAGE_DOM.length || !SWIPER_GROUP.length) return;
  let temp: any = {};

  function setControl(control: any, select: string) {
    let temp: any = {};
    for (const key in control) {
      if (key == "pagination") {
        temp[key] = {
          el: select + " " + control[key],
          clickable: swiperOtions?.ClickAble ?? false,
        };
      } else if (key == "navigation") {
        temp[key] = {
          nextEl: select + " " + control[key] + "-next",
          prevEl: select + " " + control[key] + "-prev",
        };
      }
    }
    return temp;
  }
  SWIPER_GROUP.each(function (i, el) {
    // i = i + 1;
    const SWIPER_SELECT = page + " " + marker + "_" + i;
    const SWIPER_CLASS = (marker + "_" + i).replace(".", "");
    $($(el)).addClass(SWIPER_CLASS);
    const res = JSON.stringify(controls) !== "{}" ? setControl(controls ?? {}, SWIPER_SELECT) : {};
    temp[SWIPER_CLASS] = new Swiper(SWIPER_SELECT + " .swiper", {
      ...swiperOtions,
      ...res,
    });
    if (pane && pane != "") {
      // const EL = $(pane)[i] as any;
      const EL = $(temp[SWIPER_CLASS].el).parents(".tab-pane")[0] as any;
      EL.swiperList = EL.swiperList || [];
      EL.swiperList.push(temp[SWIPER_CLASS]);
    }
  });

  if (!window.swiperGroup || !groupName) return;
  window.swiperGroup[groupName] = {
    ...window.swiperGroup[groupName],
    ...temp,
  };
}


export function createdThumbs(swiper: Swiper, thumbsSelect: string, opts?: SwiperOptions) {
  const slideList = $(swiper.el).find(".swiper-slide");
  const container = $(thumbsSelect); // 缩略图Swiper
  const wrapper = $("<div class='swiper-wrapper'></div>");
  if (!slideList.length || !container.length) return;
  const tempFragment = document.createDocumentFragment();
  slideList.each((_i, item) => {
    const img = $(item).find("img").clone().removeClass("zoomSl")[0];
    const slide = $(`<div class='swiper-slide'><figure class='img-box'></figure></div>`);
    slide.find(".img-box").append(img);
    tempFragment.appendChild(slide[0]); // Append the slide to the fragment
  });
  wrapper.append(tempFragment);
  container.append(wrapper);
  const THUMBS = new Swiper(container[0], opts ?? {});
  swiper.thumbs.swiper = THUMBS;
  swiper.on("afterInit", (S) => {
    S.thumbs.init();
    S.thumbs.update(true);
    S.update();
  });
  swiper.on("slideChange", (S) => {
    const thumbs = S.thumbs.swiper;
    const currentIdx = thumbs.params.loop ? S.activeIndex : S.realIndex;
    thumbs.slideTo(currentIdx, 500, false);
  });
}


export class SwiperVideo {
  private _Swiper: Swiper | undefined = undefined;
  constructor(swiper: Swiper, opts?: any) {
    this._Swiper = swiper;
    if (!this._Swiper) throw Error("swiper can't not be empty.");
    this._addEventListener();
  }
  private _addEventListener() {
    this._Swiper!.on("init", (S) => {
      S.autoplay.stop();
      const videoList = $(S.slides).find("video");
      if (!videoList.length) return;
      videoList.on("ended", () => {
        S.slideNext();
        S.autoplay.start();
      });
    });
    this._Swiper!.on("slideChangeTransitionStart", (S) => {
      $(S.slides).each((_i, item) => {
        const V = this.getVideo(item);
        if (V) V[0].pause();
      });
      const currentSlide = S.slides[S.activeIndex];
      const video = this.getVideo(currentSlide);
      if (video) {
        S.autoplay.stop();
        video[0].play();
      } else {
        S.autoplay.start();
      }
    });
  }
  getVideo(target: HTMLElement | Element) {
    const res = $(target).find("video");
    if (res && res.length) {
      return res;
    } else {
      return false;
    }
  }
}