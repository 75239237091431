import Swiper, { Autoplay } from "swiper7";
import { INIT, ImportBundle, lazyLoad, handleSendInquiry } from "../common/utils/utils";
import { autoInitSwiper } from "../common/utils/swiperUtils";
$(function () {
  if (window.innerWidth === 1350 || window.innerWidth === 412) {
    $(document).one("mousemove", () => {
      initFunction();
    });
    $(document).one("scroll", () => {
      initFunction();
    });
  } else {
    initFunction();
  }
});

let initFunction = () => {
  lazyLoad();
  ImportBundle({ type: "css", onlyIndex: true }, [
    "https://static.yigetechcms.com/public/css/swiper-bundle-741.css",
    "https://static.yigetechcms.com/public/css/fancybox@5.0.0.min.css",
    "https://static.yigetechcms.com/public/css/animate.min.css",
    "https://static.yigetechcms.com/public/css/bootstrap.min.css",
  ]);
  ImportBundle({ type: "js", onlyIndex: true }, [
    { src: "https://static.yigetechcms.com/public/js/bootstrap.min.js" },
    {
      src: "https://static.yigetechcms.com/public/js/wow.min.js",
      fn: () => {
        new WOW().init();
      },
    },
    {
      src: "https://static.yigetechcms.com/public/js/fancybox@5.0.0.min.js",
      fn: () => {
        window.Fancybox.bind("[data-fancybox]", {
          Thumbs: {
            type: "classic",
          },
          Toolbar: {
            display: {
              left: ["infobar"],
              middle: ["zoomIn", "zoomOut", "toggle1to1", "rotateCCW", "rotateCW", "flipX", "flipY"],
              right: ["slideshow", "thumbs", "close"],
            },
          },
        });
      },
    },
  ]);
  INIT({ h: 240 });
  initSwiper();
  initEvent();
  initFunction = () => {};
};
function initEvent() {
  $(".search-box .searchContent").on("click", function (event) {
    $(this).parent().addClass("active");
    $(document).one("click", () => {
      $(this).parent().removeClass("active");
    });
    event.stopPropagation();
  });
  $(".search-box .searchContent .gq-search").on("click", function () {
    const FLAG = $(this).parents(".search-box").hasClass("active");
    if (!FLAG) return;
    const FORM = $(this).siblings("form")[0] as HTMLFormElement;
    FORM.submit();
  });
  handleSendInquiry({formId: "#inquiryForm_index", trigger: "#submitButton_index"})
}

/*! SWIPER */
function initSwiper() {
  Swiper.prototype.activeTo = function (idx) {
    const _ = this as Swiper;
    const ACTIVE_ITEM = _.slides[idx];
    const PREV_ITEM = _.slides[idx - 1];
    const NEXT_ITEM = _.slides[idx + 1];
    _.slideTo(idx);
    $(ACTIVE_ITEM).find("a[role=tab]").tab("show");
    $(ACTIVE_ITEM).siblings().removeClass("active ready-prev ready-next");
    $(ACTIVE_ITEM).addClass("active");
    $(PREV_ITEM).addClass("ready-prev");
    $(NEXT_ITEM).addClass("ready-next");
  };
  window.swiperGroup = {
    index: {},
  };

  const index_banner = new Swiper(".index_banner .swiper", {
    modules: [Autoplay],
    loop: true,
    autoplay: {
      disableOnInteraction: false,
    },
  });
  window.swiperGroup.index["index_banner"] = index_banner;

  const index_product_left = new Swiper(".index_product_left .swiper-box .swiper", {
    direction: "vertical",
    centeredSlides: true,
    slidesPerView: 5,
    slidesPerGroup: 1,
    on: {
      init: (swiper) => {
        $(swiper.el)
          .find("a[role=tab]")
          .on("click", function () {
            swiper.activeTo($(this).parent().index());
          });
        $(".index_product_left .swiper-box .custom-swiper-prev").on("click", () => {
          const ACTIVE_INDEX = $(swiper.el).find(".swiper-slide.active").index();
          if (ACTIVE_INDEX - 1 < 0) return;

          swiper.activeTo(ACTIVE_INDEX - 1);
        });
        $(".index_product_left .swiper-box .custom-swiper-next").on("click", () => {
          const ACTIVE_INDEX = $(swiper.el).find(".swiper-slide.active").index();
          swiper.activeTo(ACTIVE_INDEX + 1);
        });
      },
    },
  });
  window.swiperGroup.index["index_product_left"] = index_product_left;

  const index_case = new Swiper(".index_case .swiper-box .swiper", {
    modules: [Autoplay],
    loop: true,
    autoplay: {
      disableOnInteraction: false,
    },
    centeredSlides: true,
    slidesPerView: "auto",
    spaceBetween: 40,
    on: {
      beforeInit: (swiper) => {
        if(!swiper.$el.find(".swiper-slide").length) {
          swiper.$el.find(".swiper-wrapper").append("<div class='swiper-slide'></div>")
        };
      }
    }
  });
  window.swiperGroup.index["index_case"] = index_case;

  autoInitSwiper({
    page: "._index_ .index_product_right",
    marker: ".swiper-box",
    groupName: "index",
    pane: ".index_product_right .tab-pane",
    swiperOtions: {
      isPane: true,
      spaceBetween: 40,
      slidesPerView: "auto",
      slidesOffsetBefore: 10,
      slidesOffsetAfter: 10,
      modules: [Autoplay],
      autoplay: {
        disableOnInteraction: false,
      },
      on: {
        beforeInit: (swiper) => {
          if ($(swiper.el).find(".swiper-slide").length > 4) {
            swiper.params.loop = true;
          }
        },
      },
    },
  });

  autoInitSwiper({
    page: "._index_ .index_service",
    marker: ".swiper-box",
    groupName: "index",
    pane: ".index_service .tab-pane",
    swiperOtions: {
      modules: [Autoplay],
      loop: true,
      autoplay: {
        disableOnInteraction: false,
      },
      isPane: true,
      spaceBetween: 40,
      slidesPerView: "auto",
    },
  });
}

