export function lazyLoad() {
  const lazyImages = $("body").find("img[yige-src],*[yige-background]");
  lazyImages.each((_i, el) => {
    const SRC = $(el).attr("yige-src");
    const BACKGROUND = $(el).attr("yige-background");
    if (SRC) {
      $(el).attr("src", SRC);
    }
    if (BACKGROUND) {
      $(el).css("background-image", `url(${BACKGROUND})`);
    }
  });
}
export function resetImgSize({ nodeList, setImg = true, setBox = false, cb }: ResetImgSizeFn) {
  const temp: any[] = [];
  nodeList.each((_i, img) => {
    if (!$(img).is("img")) return;
    function resizeImage(image: HTMLImageElement) {
      const originalH = image.naturalHeight / 10 + "rem";
      const originalW = image.naturalWidth / 10 + "rem";
      if (setBox) {
        $(image).parents(".img-box").eq(0).css({
          height: originalH,
          width: originalW,
        });
      }
      if (setImg) {
        $(image).css({
          height: originalH,
          width: originalW,
        });
      }
      temp.push({
        item: img,
        w: originalW,
        h: originalH,
      });
      if (cb && typeof cb === "function") cb();
    }
    if ((img as HTMLImageElement).complete) {
      // 如果图片已加载
      resizeImage(img as HTMLImageElement);
    } else {
      $(img).on("load", function () {
        resizeImage(this as HTMLImageElement);
      });
    }
  });
  return temp;
}
export function handleSendInquiry({ formId, trigger, opts }: SendInquiryOpts) {
  const formEl = $(formId)[0] as HTMLFormElement;
  $(trigger).on("click", () => {
    const triggerElement = $(formEl).data("triggerElement");
    const formData = new FormData(formEl);
    const readyToSend = beforeSubmit(formData);
    if (readyToSend) sendAjax(formData, triggerElement);
  });
  const beforeSubmit = (data: FormData) => {
    let FLAG = true;
    data.forEach((_val, key, data) => {
      const is_required = $(formEl[key]).attr("required") == "required";
      if (is_required && data.get(key) === "") {
        FLAG = false;
        let msg: string | undefined = undefined;
        if (opts && opts.inquiryMsg && opts.inquiryMsg[key]) {
          msg = opts.inquiryMsg[key];
        }
        throw rejectMessage(key, msg);
      }
    });
    /*! custom Setting Start */
    if (opts && opts.fn && typeof opts.fn === "function") {
      opts.fn(data);
    }
    return FLAG;
  };
  const rejectMessage = (key: string, msg?: string) => {
    formEl[key].focus();
    alert(msg ?? `${key} can not be empty!`);
  };
  const sendAjax = (data: FormData, originalTrigger: HTMLElement) => {
    $.ajax({
      processData: false,
      contentType: false,
      type: "POST",
      url: "/inquiry",
      data: data,
      success: function (jsonResult) {
        if (jsonResult.code == 200) {
          ($(formEl)[0] as HTMLFormElement).reset();
          if (opts && opts.onSuccess && typeof opts.onSuccess === "function") {
            opts.onSuccess(jsonResult, originalTrigger);
          } else {
            location.href = "/page/thanks.html";
          }
        } else {
          alert(jsonResult.msg);
        }
        $(":input[name = '__token__']").val(jsonResult.data.token);
      },
      error: function (data) {
        alert(data.responseText);
      },
    });
  };
}
export function INIT({ h, searchTriggle }: { h?: number; searchTriggle?: string }) {
  const isMobile = $(window).width()! <= 1024;
  const header = $("header");
  const scrollTopButton = $(".scroll-top");
  const searchBox = $(".search-box");
  const initHeaderSticky = (height: number): void => {
    $(window).on("scroll", () => {
      if ($(window).scrollTop()! > height) {
        header.addClass("fixeds");
        scrollTopButton.show(400);
      } else {
        header.removeClass("fixeds");
        scrollTopButton.hide(400);
      }
    });
  };
  const initSearch = (): void => {
    if (!searchBox.length || !searchTriggle) return;
    $(`header ${searchTriggle}`).on("click", (event) => {
      searchBox.slideToggle(500);
      $(document).one("click", () => {
        searchBox.slideUp(500);
      });
      event.stopPropagation();
    });
    searchBox.on("click", (event) => {
      event.stopPropagation();
    });
  };
  const initMobileMenu = (): void => {
    $(".header .trigger").on("click", function () {
      $(this).toggleClass("trigger_active");
      $(this).siblings(".content_mt_ul").slideToggle(300);
    });
    $(".header .mobile_nav .nav_bg").on("click", function () {
      $("html").css("overflow-y", "unset");
      $(".mobile_nav .mobile_nav_content").removeClass("nav_show");
    });
    $(".header .mobile_nav .cus-bars").on("click", function () {
      $("html").css("overflow-y", "hidden");
      $(".mobile_nav .mobile_nav_content").addClass("nav_show");
    });
  };
  scrollTopButton.on("click", () => {
    $(window).scrollTop(0);
  });
  if (!isMobile && h && typeof h === "number") {
    initHeaderSticky(h);
  }
  if (!isMobile && searchTriggle && typeof searchTriggle === "string") {
    initSearch();
  }
  if (isMobile) {
    initMobileMenu();
  }
}
export function ImportBundle(options: BundleImporter.BundleOptions, src: BundleImporter.ScriptSrc[] | string[]) {
  const PATH = window.location.pathname;
  const INDEX_FLAG = /index.html$/g.test(PATH);
  if (!options.isIndex && !options.onlyIndex && (PATH == "/" || INDEX_FLAG)) return;
  if (options.onlyIndex && PATH !== "/" && !INDEX_FLAG) return;
  if (options.isMobile && document.body.clientWidth < 768) return;

  let _count: number = src.length || 0;
  let _scritpLoaded: number = 0;
  let _resolve: (value: boolean) => void = () => {};
  const documentFragment: DocumentFragment = document.createDocumentFragment();

  // 生成css标签
  function createCssLink(linkSrc: string[]) {
    linkSrc.forEach((link) => {
      if (typeof link !== "string") return;
      const L = document.createElement("link");
      L.rel = "stylesheet";
      L.href = link;
      documentFragment.appendChild(L);
      _scritpLoaded++;
      checkAllScriptsLoaded();
    });
  }
  // 生成script标签
  function createScriptSrc(linkSrc: BundleImporter.ScriptSrc[]) {
    const onload = (item: BundleImporter.ScriptSrc) => {
      _scritpLoaded++;
      if (item.fn && typeof item.fn == "function") {
        item.fn();
      }
      checkAllScriptsLoaded();
    };

    linkSrc.forEach((item) => {
      if (!item || (!item.src && typeof item !== "object")) return;
      if (item.isMobile && document.body.clientWidth < 768) {
        _count--;
        return;
      }
      const script = document.createElement("script");
      script.src = item.src;
      script.defer = item?.defer ?? false;
      script.async = item?.async ?? false;
      script.onload = () => onload(item);
      documentFragment.appendChild(script);
    });
  }
  function checkAllScriptsLoaded() {
    if (_scritpLoaded === _count) {
      if (options.callback && typeof options.callback == "function") {
        options.callback();
      }
      _resolve(true);
    }
  }
  // 导入
  function Import() {
    return new Promise<boolean>((resolve, _reject) => {
      _resolve = resolve;
      const container = document.getElementsByTagName("head")[0];
      const Pos = container.querySelector('link[rel="stylesheet"]');
      if (options.type === "css") {
        createCssLink(src as string[]);
      } else {
        createScriptSrc(src as BundleImporter.ScriptSrc[]);
      }
      if (options.position && options.position === "end") {
        container.appendChild(documentFragment);
      } else {
        container.insertBefore(documentFragment, Pos);
      }
    });
  }
  return Import();
}

export function isMobile(w: number = 620): boolean {
  const windowWidth = $(window).width()!;
  return windowWidth <= w;
}
const preventDefault = (e: Event) => {
  e.preventDefault();
};
export function catchWheelEvent(enable: boolean) {
  if (enable) {
    $("html").css("overflow-y", "hidden");
    document.addEventListener("wheel", preventDefault, { passive: false });
    document.addEventListener("touchmove", preventDefault, { passive: false });
  } else {
    $("html").css("overflow-y", "initial");
    document.removeEventListener("wheel", preventDefault);
    document.removeEventListener("touchmove", preventDefault);
  }
}

/**
 * 依赖 "--item" css变量
 * @param {JQuery<HTMLElement>} list
 */
export function listToGroup(list: JQuery<HTMLElement>) {
  const breakpoint = Number(list.css("--item"));
  const tempList: any[] = [];
  for (let i = 0; i < list.length; i += breakpoint) {
    const group = list.slice(i, i + breakpoint);
    tempList.push(group);
  }
  return tempList;
}
